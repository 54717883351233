<template>

  <template v-if="$route.path==='/login' || $route.path==='/'">
    <router-view @setUser="setUser"></router-view>
  </template>
  <template v-else>
  <q-layout view="hHh LpR fFf">

    <q-header class="bg-white text-grey-8" elevated>
      <q-toolbar>
        <q-btn dense flat round icon="menu" @click="onClickMenu"/>

        <q-toolbar-title>
         <!-- <q-avatar>
            <img src="https://cdn.quasar.dev/logo-v2/svg/logo-mono-white.svg" style="height: 30px">
          </q-avatar> -->
          <span style="letter-spacing: 1.2px;margin-left: 16px">AJ Infosoft | {{$route.name}}</span>
        </q-toolbar-title>
        <div class="d-flex nd-info pr-3 pl-1 mt-2" style="align-items: center;">
         <!-- <q-avatar></q-avatar> -->
          <div :class="'nd-user-icon text-white bg-blue'">
            <span style="font-size: 12px">{{user.name&&user.name.charAt(0)}}</span>
          </div>
          <div>
            <span class="ml-3 d-block" style="font-size: 14px">{{user.name}}</span>
            <span class="ml-3 d-block" style="font-size: 14px">{{user.email}}</span>
          </div>
        </div>
        <q-btn icon="logout" flat round title="Logout" @click="onLogout"/>
      </q-toolbar>
    </q-header>

    <q-drawer show-if-above v-model="drawer" side="left" bordered :mini="miniVariant" :width="200">
      <q-scroll-area class="fit">
        <q-list>

          <template v-for="(m, index) in menuList" :key="index">
            <q-item clickable :active="$route.name == m.label" :to="m.path" active-class="text-blue" v-ripple>
              <q-item-section avatar><q-icon :name="m.icon" /></q-item-section>
              <q-item-section>{{ m.label }}</q-item-section>
            </q-item>
            <q-separator :key="'sep' + index"  v-if="m.separator" />
          </template>
          <q-item clickable v-ripple style="position: absolute;bottom: 0" @click="drawer=!drawer">
            <q-item-section avatar><q-icon name="chevron_left" /></q-item-section>
          </q-item>

        </q-list>
      </q-scroll-area>
    </q-drawer>

    <q-page-container>
      <div class="q-pa-lg"> <router-view/> </div>
    </q-page-container>

  </q-layout>
  </template>
</template>

<script>
import { ref } from 'vue'
import axios from "axios";
import Constants from "./constants/Constants";

export default {
  setup () {
    const drawer = ref(true);
    const miniVariant = ref(true);
    const menuList  = ref([
            { icon: 'dashboard', label: 'Dashboard', path:'/', separator: true},
            { icon: 'redeem', label: 'Promo Codes', path:'/promo-codes'},
            { icon: 'people', label: 'Customers', path:'/customers'},
            { icon: 'settings', label: 'Settings', path:'/settings'},
    ]);
    const onClickMenu = () => {
      if(!drawer.value){
        drawer.value = true;
        miniVariant.value = true
      } else miniVariant.value = !miniVariant.value
    };
    return { drawer, miniVariant, menuList, onClickMenu}
  },
  data(){
    return{
      user: {}
    }
  },
  methods:{
    setUser(user){
      this.user = user;
    },
    onLogout(){
      this.$q.dialog({
        title: 'Confirm', cancel: true, persistent: false,
        message: `Are you sure, You want to logout ?`,
      }).onOk(() => this.logout());
    },
    logout(){
      axios({method: 'POST', url: Constants.api_url + 'admin/logout', data: {userId: this.user.userId}}).then(response => {
        if (response.data.status) {
          localStorage.clear();
          this.$router.push('/login');
        } else this.$q.notify(response.data.message || 'Logout API Failed');
      }).catch(e => console.log('logout error', e))
    }
  },
  created() {
    if(localStorage.user) this.setUser(JSON.parse(localStorage.user));
  }
}
</script>

<style lang="scss">
@import'./styles/spacing.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.nd-info{
  transition: 0.3s;
}
.nd-info:hover{
  cursor: pointer;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.11);
  border-radius: 50px;
}
.nd-user-icon{
  width: 35px;
  height: 35px;
  border-radius: 50px;
  text-align: center;
  line-height: 35px;
  color: #fff;
  font-weight: 600;
}
.avatar{
  cursor: pointer;
}
</style>
