import { HttpLink, split, ApolloClient, InMemoryCache } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";

const httpLink = new HttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
});

export const wsLink = new WebSocketLink({
  uri: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
  options: {
    reconnect: true,
    connectionParams() { return {
        headers: { 'X-Hasura-Admin-Secret': process.env.VUE_APP_HASURA_ADMIN_SECRET },
    }},
  },
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Hasura-Admin-Secret': process.env.VUE_APP_HASURA_ADMIN_SECRET,
    },
  }
});

const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
      )
    },
    wsLink,
    httpLink,
);

export const apolloClient = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  connectToDevTools: true
});

