import { createRouter, createWebHashHistory } from 'vue-router'
import Dashboard from "../views/Dashboard";

const routes = [
    { path: '/', redirect: { name: 'Dashboard' } },
    {
        path: '/dashboard', name: 'Dashboard',
        component: () => import(/* webpackChunkName: "about" */ '../views/Dashboard.vue'),
        meta: { requiresAuth: true },
    },
    {
      path: '/login', name: 'Login',
      component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
      meta: { requiresAuth: false }
    },
    {
      path: '/promo-codes', name: 'Promo Codes',
      component: () => import(/* webpackChunkName: "about" */ '../views/PromoCodes.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/customers', name: 'Customers',
      component: () => import(/* webpackChunkName: "about" */ '../views/Customers.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings', name: 'Settings',
      component: () => import(/* webpackChunkName: "about" */ '../views/Settings/Settings.vue'),
      meta: { requiresAuth: true }
    },
    {
      path: '/settings/business-category', name: 'Business Category',
      component: () => import(/* webpackChunkName: "about" */ '../views/Settings/BusinessCategory'),
      meta: { requiresAuth: true }
    }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes
});

export default router

router.beforeEach((to, from, next) => {
  if(to.meta.requiresAuth) {
    if(localStorage.token) next();
    else router.replace({path: '/login'})
  }else {
    if(localStorage.token) router.replace({ path: '/dashboard'});
    else next()
  }
});